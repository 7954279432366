import * as capSvc from './services/internal-confidential-service.js';
import internalConfidentialRequest from './models/internal-Confidential/searchRequestModel.js'
import internalConfidentialDisplay from "./models/internal-Confidential/searchDisplayModel.js";
import {keyTabNavigation} from "../utilities/navigation.js";
import {footer} from "./internal-confidential-searchFooter.js";

window.loadJobDetail = loadJobDetail;
window.hideCaliNotice = hideCaliNotice;
window.acceptGdpr = acceptGdpr;

let brandId;
console.log('brand1: ' + sessionStorage.getItem('brandId'));
brandId = capSvc.getBrand();
console.log('brand2: ' + sessionStorage.getItem('brandId'));
let direction = "asc"

const columns = [
  {path: "title", name: "Title", justification: "text-left"},
  {path: "id", name: "ID", justification: "text-left"},
  {path: "city", name: "City", justification: "text-left"},
  {path: "stateProvince", name: "State", justification: "text-center"},
  {path: "datePosted", name: "Date Posted", justification: "text-center"}
];

let bodyRequest = internalConfidentialRequest
let displayData = internalConfidentialDisplay

if (window.location.search === "") {
  //
  const searchDiv = document.getElementById("brandBody");
  searchDiv.innerHTML =
    `
  ${sessionStorage.getItem('headerHtml')}
`
  const tableSearchDisplayDiv = document.createElement("div")
  tableSearchDisplayDiv.setAttribute("id", "searchTableDiv")
  tableSearchDisplayDiv.setAttribute("class", "container clearfix pb-1")

  const searchTable = document.createElement("div")
  searchTable.setAttribute("id", "searchTable")
  searchTable.setAttribute("class", "container")
  tableSearchDisplayDiv.appendChild(searchTable)

  const s = document.createElement("script")
  s.type = 'module'
  s.src = '../assets/js/internal-confidential-searchFilter.js'
  tableSearchDisplayDiv.appendChild(s)

  const jobs = capSvc.getInternalJobs(bodyRequest)
  const jobs2 = jobs.then(res => res.json())
  jobs2.then(json => {
    displayData = json
    if (displayData.searchResults !== null) {
      populateSearchTable(displayData)
      footer(displayData.totalResults, bodyRequest["resultsPerPage"], 1)
      setSortHeader()
      keyTabNavigation()
    }
  })
  searchDiv.appendChild(tableSearchDisplayDiv)
  removePreloader();
}

function loadJobDetail(jobId, stateProvince) {
  if (stateProvince === 'CA') {
    capSvc.showCaliNotice(jobId + ".html");
  } else {
    window.open(jobId + ".html", "_self");
  }
}

function populateSearchTable(displayData, pageSize = null) {
  // console.log(displayData);

  searchTable.innerHTML = `
        <div id="jobCount" class="d-flex justify-content-center mt-3">
            <p id="jobCountDisplay">${displayData['totalResults']} Positions Found</p>
        </div>
         <div class="table-responsive">
 					<table id="jobSearchTable" class="table">
					  <thead>
							<tr>
                 ${columns.map(({path, name, justification}) => (
                    `<th class="sorting sorting_asc ${justification}" id="${path}">${name}
                                    <i class="fa fa-chevron-up"></i>
                                  </th>`
                  )).join('')}
						  </tr>
						</thead>
						<tbody>
                ${displayData['searchResults'].map((rowData) => (
                  `<tr tabindex="${0}" class="jobSearchRow" onclick="loadJobDetail(${rowData["id"]},'${rowData["stateProvince"]}')">
                                                 ${columns.map(({path, justification}) => (
                    formatData(path, rowData, justification)
                  )).join('')}
                             </tr>`
                )).join('')}
					  </tbody>
				  </table>
         </div>
<!-- Modal -->
        <div class="modal fade" id="caliNoticeModal" tabindex="-1" aria-labelledby="PRIVACY NOTICE TO CALIFORNIA EMPLOYEES AND JOB APPLICANTS REGARDING THE COLLECTION OF PERSONAL INFORMATION" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">PRIVACY NOTICE TO CALIFORNIA EMPLOYEES AND JOB APPLICANTS REGARDING THE COLLECTION OF PERSONAL INFORMATION</h5>
                <button type="button" class="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onclick="hideCaliNotice()" title="Close">X</button>
              </div>
              <div class="modal-body" style="font-size: 14px">
                ${sessionStorage.getItem('ccpaContent')}
              </div>
              <div class="calNoticeFooter">
                <button type="button" class="btn btn-danger" style="float:left" onclick="hideCaliNotice()" title="Close" tabindex="0">Decline</button>
                <button tabindex="0" type="button" class="btn btn-application" style="float:right"><a id="caliModalAcceptHref" href="" target="_self" title="Accept" tabindex="0">Accept</a></button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" id="noJobsFound" hidden>
            <p>Sorry, we couldn't find any positions matching your criteria.</p>
            <div>
                <a href="../../${brandId}">
                    <div class="btn btn-application mt-3 w-25">return to search</div>
                </a>
            </div>
        </div>
`
}

function formatData(path, rowData, justification) {
    return (`<td id="${path}" class="${justification}" tabindex="${-1}">
      ${rowData[path]}
    </td>`)
}

export function setSortHeader() {
  const table = document.getElementById('jobSearchTable');

  // Query the headers
  const headers = table.querySelectorAll('th');

  // Loop over the headers
  [].forEach.call(headers, function (header, index) {
    header.addEventListener('click', function () {
      // This function will sort the column
      sortColumn(index, header.id);
    });
  });

  const tableBody = table.querySelector('tbody');
  const rows = tableBody.querySelectorAll('tr');

  // Track sort directions
  let directions = Array.from(headers).map(function (header) {
    return '';
  });

  console.log(headers);
  console.log(directions);

  const sortColumn = function (index, id) {
    if ($('#' + id)[0].classList.contains('sorting_asc')) {
      direction = "asc"
    } else {
      direction = "desc"
    }

    // <i className="fa fa-chevron-up"></i>

    console.log($('#' + id)[0].querySelector('.fa').classList);

    // // Get the current direction
    // const direction = directions[index] || 'asc';

    switch (id){
      case 'ID':
        bodyRequest['sortColumn'] = "PeopleSoftId"
        break
      case 'title':
        bodyRequest['sortColumn'] = "Title"
        break
      case 'city':
        bodyRequest['sortColumn'] = "City"
        break
      case 'state':
        bodyRequest['sortColumn'] = "StateProvince"
        break
      default:
        bodyRequest['sortColumn'] = "DatePosted"
        break
    }
    bodyRequest['sortDirection'] = direction

    capSvc.getJobs(bodyRequest)
      .then(res => res.json())
      .then(json => {
        displayData = json
        bindSearchTable(displayData)

        if (direction === 'asc') {
          $('#' + id)[0].classList.remove('sorting_asc');
          $('#' + id)[0].classList.add('sorting_desc');
          $('#' + id)[0].querySelector('.fa').classList.remove('fa-chevron-up');
          $('#' + id)[0].querySelector('.fa').classList.add('fa-chevron-down');

        } else {
          $('#' + id)[0].classList.remove('sorting_desc');
          $('#' + id)[0].classList.add('sorting_asc');
          $('#' + id)[0].querySelector('.fa').classList.remove('fa-chevron-down');
          $('#' + id)[0].querySelector('.fa').classList.add('fa-chevron-up');
        }

        // <i className="fa fa-chevron-up"></i>

        console.log(headers[index].querySelector('.fa').classList);
      })

    // Reverse the direction
    directions[index] = direction === 'asc' ? 'desc' : 'asc';
  };
}

export function filterSearch(event) {
  event.preventDefault()
  const state = document.getElementById("inputState").value === "" ? null : document.getElementById("inputState").value
  let distance = null
  let keyword = null
  let peopleSoftId = null
  let city = null
  let ZipCode = null
  if (!document.getElementById("radiusMiles").disabled) {
    distance = document.getElementById("radiusMiles").value
  }
  if (document.getElementById("inputTitle").value !== "") {
    keyword = document.getElementById("inputTitle").value
  }
  if (document.getElementById("inputId").value !== "") {
    peopleSoftId = document.getElementById("inputId").value
  }

  if (document.getElementById("inputCity").value) {
    city = document.getElementById("inputCity").value
  }

  if (document.getElementById("inputZipRadius").value !== "") {
    ZipCode = document.getElementById("inputZipRadius").value
  }

  console.log('keyword: ' + keyword)

  bodyRequest = {
    brandCode: 'internal-confidential',
    keywords: keyword,
    peopleSoftId: peopleSoftId,
    city: city,
    state: state,
    positionType: null,
    postalCode: ZipCode,
    distanceInMiles: distance,
    page: 0,
    resultsPerPage: 10,
    sortColumn: "DatePosted",
    sortDirection: "desc"
  }
console.log(bodyRequest)
  capSvc.getJobs(bodyRequest)
    .then(res => res.json())
    .then(json => {
      displayData = json
      bindSearchTable(displayData)
    })
}

export function setPageNumber(pageNumber) {
  bodyRequest["page"] = pageNumber - 1
  capSvc.getInternalJobs(bodyRequest)
    .then(res => res.json())
    .then(json => {
      displayData = json
      bindSearchTable(displayData)
    })
}

export function setPageDisplaySize(event) {
  bodyRequest["resultsPerPage"] = event.currentTarget.value
  document.getElementsByName("pageDisplaySize").value = bodyRequest["resultsPerPage"]
  capSvc.getInternalJobs(bodyRequest)
    .then(res => res.json())
    .then(json => {
      displayData = json
      bindSearchTable(displayData)
    })
}

function bindSearchTable(displayData) {
  if (displayData.searchResults !== null) {
    if(displayData.searchResults.length > 0){
      populateSearchTable(displayData)
      document.getElementById("tableFooter").remove()
      footer(displayData.totalResults, bodyRequest["resultsPerPage"], bodyRequest["page"] + 1)
      setSortHeader()
      keyTabNavigation()
    }
  }else{
    $('#noJobsFound')[0].removeAttribute('hidden')
    $('#jobCountDisplay').addClass('hidden')
    $('#jobSearchTable').addClass("hidden")
    $('#searchFilter').addClass('hidden')
    document.getElementById("tableFooter").remove()
  }
}

function hideCaliNotice() {
  $("#caliNoticeModal").modal("hide");
}

function acceptGdpr() {
  console.log('accepted')
  sessionStorage.setItem('cookiesAccepted','true')
  $('#gdprNotice').hide();
}
